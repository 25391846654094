import React from "react";
import { graphql, StaticQuery } from "gatsby";

export default function Balance() {
  return (
    <StaticQuery
      query={graphql`
        query Balance {
          balance: stripeBalance {
            available {
              amount
              currency
              source_types {
                card
              }
            }
            pending {
              amount
              currency
              source_types {
                card
              }
            }
            livemode
          }
        }
      `}
      render={({ balance }) => (
        <div>{/**console.log("balance", balance)*/}</div>
      )}
    />
  );
}
