import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Checkout from "../components/checkout";

export default function Faq() {
  return (
    <Layout>
      <SEO title="Buy pixels" />
      <Checkout />
    </Layout>
  );
}
