import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { loadStripe } from "@stripe/stripe-js";

import Balance from "./balance";
import "./checkout.css";

let stripePromise;
const getStripe = (key) => {
  if (!stripePromise) {
    stripePromise = loadStripe(key);
  }
  return stripePromise;
};

export default function Checkout() {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(100);

  const {
    site: {
      siteMetadata: { stripePublishableKey, stripePrice },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            stripePublishableKey
            stripePrice
          }
        }
      }
    `
  );

  const redirectToCheckout = async (event) => {
    event.preventDefault();

    if (!quantity || quantity % 100 !== 0) {
      alert("Please select a multiply of 100!!!");
      return;
    }

    setLoading(true);
    const stripe = await getStripe(stripePublishableKey);
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: stripePrice, quantity }],
      successUrl: `https://www.millioneurobillboard.com/`,
      cancelUrl: `https://www.millioneurobillboard.com/`,
    });
    if (error) {
      console.warn("Error:", error);
      setLoading(false);
    }
  };

  const selectQuantity = (event) => {
    const pixels = event.target.value;
    const regex = /^[0-9]{1,6}$/;

    if (regex.test(pixels)) {
      setQuantity(parseInt(pixels));
    } else if (!pixels) {
      setQuantity("");
    } else {
      return;
    }
  };

  return (
    <div className="section">
      <div className="center">
        <div className="title text-center">CHECKOUT</div>

        <div className="space-4" />

        <div className="paragraph text-center">
          Please select the amount of pixels you want to buy:
        </div>

        <div className="space-1" />

        <div className="checkout__form">
          <input
            placeholder="Multiple of 100"
            value={quantity}
            onChange={selectQuantity}
          />
          <button
            disabled={loading}
            onClick={redirectToCheckout}
            className={loading ? "disabled" : ""}
          >
            {`Buy ${quantity > 0 ? quantity : ""} pixel Ads`}
          </button>
        </div>

        <div className="space-4" />

        <a href="https://stripe.com/" target="_blank" rel="noreferrer">
          <img src="/images/poweredByStripe.png" alt="Powered by Stripe" />
        </a>
      </div>

      <Balance />
    </div>
  );
}
